import { gql } from '@/shared/graphql/generated/cache';

export const getBrokerAccountCacheDataQuery = gql(/* GraphQL */ `
  query GetBrokerAccountCacheData($idSs58: String!) {
    broker: brokerAccountByIdSs58(idSs58: $idSs58) {
      flipBalance
      idSs58
      bondedFlipBalance
      brokerVaultDepositAddress
      registrationId
      withdrawalAddress
      delegateBrokerId
      affiliates: brokerAccountsByDelegateBrokerId {
        nodes {
          registrationId
          idSs58
          withdrawalAddress
        }
      }
      feesEarned: brokerAccountEarnedFeesByBrokerAccountIdSs58 {
        groupedAggregates(groupBy: ASSET) {
          sum {
            amount
          }
          keys
        }
      }
    }
  }
`);

export const getBrokersBalancesQuery = gql(/* GraphQL */ `
  query GetBrokersBalances {
    brokers: allBrokerAccounts {
      nodes {
        flipBalance
        idSs58
      }
    }
  }
`);
