import { type UTCDate } from '@date-fns/utc';
import { Link } from '@/shared/components';
import { BrokerIcon } from '@/shared/icons/small';
import { getBrokerIdOrAlias, type OverviewStats } from 'packages/block-explorer/pages/brokers';
import { AccountStatsWithChart } from '../AccountStatsWithChart';

const AllBrokersBadge = () => (
  <div className="flex flex-row items-center space-x-1 rounded-md bg-cf-gray-4 px-1.5 py-0.5 text-12 text-cf-light-3">
    <BrokerIcon width={14} />
    <span>All brokers</span>
  </div>
);

const BrokerAccountLegendLine = ({
  account: broker,
  stat,
}: {
  account: {
    brokerId?: string;
    broker?: {
      account: { idSs58?: string };
    };
    key: string;
    volume: string;
    swapCount: string;
    fees: string;
  };
  stat: BrokerStat;
}) => (
  <div className="flex flex-row items-center justify-between" key={broker.brokerId}>
    <div className="text-cf-light-3">
      {broker.broker?.account.idSs58 ? (
        <Link href={`/brokers/${broker.broker?.account.idSs58}`} underline>
          {getBrokerIdOrAlias(broker.broker?.account)}
        </Link>
      ) : (
        getBrokerIdOrAlias(broker.broker?.account)
      )}
    </div>
    <div className="text-white">{broker[stat]}</div>
  </div>
);

const statMap = {
  volume: {
    title: 'Volume',
    subtitle: 'Amount of volume from Chainflip swaps via brokers',
    tableColumnLabel: 'Volume',
  },
  swapCount: {
    title: 'Swaps',
    subtitle: 'Amount of swaps through Chainflip via a broker',
    tableColumnLabel: 'Amount',
  },
  fees: {
    title: 'Collected fees',
    subtitle: 'Amount of broker fees collected',
    tableColumnLabel: 'Amount',
  },
} as const;

type BrokerStat = keyof typeof statMap;

export const BrokerStatsWithChart = <
  RawData,
  ChartData extends { chartDates: UTCDate[]; total: string },
>({
  stat,
  loading,
  startDate,
  endDate,
  rawData,
  aggregateData,
  getLegendLabels,
  Chart,
  overviewStats,
}: {
  stat: BrokerStat;
  loading: boolean;
  startDate: UTCDate;
  endDate: UTCDate;
  rawData: RawData;
  aggregateData: (startDate: UTCDate, endDate: UTCDate, data: RawData) => ChartData;
  getLegendLabels: (data: ChartData) => string[];
  Chart: React.FC<{ data: ChartData; loading: boolean }>;
  overviewStats: OverviewStats;
}) => (
  <AccountStatsWithChart
    stat={stat}
    loading={loading}
    startDate={startDate}
    endDate={endDate}
    rawData={rawData}
    aggregateData={aggregateData}
    getLegendLabels={getLegendLabels}
    Chart={Chart}
    overviewStats={overviewStats}
    Badge={AllBrokersBadge}
    statInfo={statMap}
    AccountLegendLine={BrokerAccountLegendLine}
  />
);
