import { eachUtcDayOfInterval } from '@chainflip/utils/date';
import { formatUsdValue } from '@chainflip/utils/number';
import { abbreviate } from '@chainflip/utils/string';
import { ResponsiveLine, type SliceTooltipProps } from '@nivo/line';
import BigNumber from 'bignumber.js';
import { ChartLegendRectangle, Divider } from '@/shared/components';
import { ChartTooltip } from '@/shared/components/ChartTooltip';
import { useScreenSize } from '@/shared/hooks';
import { formatWithNumeral, isIdSs58 } from '@/shared/utils';
import { colors, getDateAxisTicks } from '@/shared/utils/charts';
import {
  type BrokerAggregateByDateRange,
  getBrokerIdOrAlias,
  getTopBrokersIdSs58,
} from '../../pages/brokers';

const SliceTooltip = (props: SliceTooltipProps) => (
  <ChartTooltip activeXAxisItem={props.slice.x}>
    <div className="w-[240px] space-y-1">
      <div className="flex flex-col items-center">
        <div className="text-12 text-cf-light-2">
          {new Date(props.slice.points[0].data.x).toLocaleDateString(undefined, {
            timeZone: 'UTC',
          })}{' '}
          UTC
        </div>
        <div className="text-24 text-cf-white">
          {formatUsdValue(
            BigNumber.sum(...props.slice.points.map((point) => Number(point.data.y))),
          )}
        </div>
      </div>
      <div className="text-12 leading-6 text-cf-white">
        {props.slice.points.toReversed().map((point) => {
          const id = point.id.split('.')[0];
          return (
            <div className="flex flex-col gap-y-2.5" key={id}>
              {id === 'Others' && <Divider />}
              <div
                className="flex flex-row items-center justify-between text-cf-white"
                key={point.id}
              >
                <div className="flex flex-row items-center space-x-2">
                  <ChartLegendRectangle fill={point.color} />
                  <div>{isIdSs58(id) ? abbreviate(id) : id}</div>
                </div>
                <div>{formatUsdValue(Number(point.data.y), false)}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </ChartTooltip>
);

const aggregateData = (
  startDate: Date,
  endDate: Date,
  allBrokers: NonNullable<BrokerAggregateByDateRange>,
) => {
  const chartDates = eachUtcDayOfInterval({
    start: startDate,
    end: endDate,
  });
  const chartAggregates = chartDates.flatMap((date) =>
    allBrokers.filter((item) => new Date(Number(item.day)).valueOf() === date.valueOf()),
  );
  const topBrokerSs58Ids = getTopBrokersIdSs58(chartAggregates, 'volume');

  const chartSeries = topBrokerSs58Ids.map((brokerIdSs58) => ({
    id: getBrokerIdOrAlias(chartAggregates.find((agg) => agg.idSs58 === brokerIdSs58)),
    data: chartDates.map((date) => ({
      x: date,
      y:
        chartAggregates
          ?.filter(
            (agg) =>
              agg.idSs58 === brokerIdSs58 && date.valueOf() === new Date(Number(agg.day)).valueOf(),
          )
          .reduce((acc, broker) => acc + (broker.volume || 0), 0) || 0,
    })),
  }));

  if (chartSeries.length > 0)
    chartSeries.push({
      id: 'Others',
      data: chartDates.map((date) => ({
        x: date,
        y:
          chartAggregates
            ?.filter(
              (agg) =>
                !topBrokerSs58Ids.includes(agg.idSs58!) &&
                date.valueOf() === new Date(Number(agg.day)).valueOf(),
            )
            .reduce((acc, broker) => acc + (broker.volume || 0), 0) || 0,
      })),
    });

  const total = chartAggregates?.reduce((prev, curr) => prev + (curr.volume || 0), 0) || 0;

  return {
    chartDates,
    chartSeries,
    total: formatUsdValue(total, false),
  };
};

type ChartData = ReturnType<typeof aggregateData>;

const Chart = ({ data: memoizedData, loading }: { data: ChartData; loading: boolean }) => {
  const { isMobile } = useScreenSize();
  const brokerColors = Object.fromEntries(
    memoizedData.chartSeries.map((datum, index) => [datum.id, colors[index]]),
  );

  return (
    <ResponsiveLine
      data={memoizedData.chartSeries}
      colors={(datum) => brokerColors[datum.id]}
      margin={{ top: 20, right: 20, bottom: 50, left: 55 }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: isMobile ? -60 : 0,
        tickValues: getDateAxisTicks(memoizedData.chartDates),
        format: (date) =>
          date.toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC',
          }),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickValues: 7,
        format: (number) => formatWithNumeral(number),
      }}
      yScale={{
        type: 'linear',
        max: loading ? 1 : undefined,
        stacked: true,
        nice: true,
      }}
      theme={{
        legends: {
          text: {
            fill: '#909090',
          },
        },
        axis: {
          ticks: {
            text: {
              fill: '#5F5F5F',
              fontFamily: 'Aeonik-Medium',
              fontSize: 12,
            },
          },
        },
        grid: {
          line: {
            stroke: '#252525',
            strokeWidth: 1,
            strokeDasharray: 2,
          },
        },
        crosshair: {
          line: {
            stroke: '#FFFFFF',
            strokeOpacity: 0.4,
            strokeWidth: 1,
            strokeDasharray: '2',
          },
        },
      }}
      defs={colors.map((color) => ({
        id: `BrokerVolumeChart-${color}`,
        type: 'linearGradient',
        colors: [
          {
            offset: 0,
            color: `color-mix(in oklab, ${color} 70%, black)`,
          },
          {
            offset: 100,
            color: `color-mix(in oklab, ${color} 45%, black)`,
          },
        ],
      }))}
      fill={colors.map((color) => ({
        match: { color },
        id: `BrokerVolumeChart-${color}`,
      }))}
      lineWidth={1}
      enableArea
      areaOpacity={1}
      enablePoints={false}
      enableSlices="x"
      enableGridX={false}
      gridYValues={7}
      sliceTooltip={SliceTooltip}
      animate={false}
      role="application"
    />
  );
};

const getLegendLabels = (memoizedData: ChartData) =>
  memoizedData.chartSeries.map((broker) => broker.id);

export const volumeChartProps = {
  getLegendLabels,
  Chart,
  aggregateData,
};
