import { useMemo } from 'react';
import { formatUsdValue } from '@chainflip/utils/number';
import { abbreviate } from '@chainflip/utils/string';
import BigNumber from 'bignumber.js';
import { AgeWithTooltip, CompactSwapRoute, CopyButton, Link } from '@/shared/components';
import { Addresses } from '@/shared/components/Addresses';
import Pill from '@/shared/components/flip-ui-kit/Pill';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import TableRow from '@/shared/components/TableRow';
import { type OpenChannelFragment as Channel } from '@/shared/graphql/generated/explorer/graphql';
import useRemotePagination from '@/shared/hooks/useRemotePagination';
import { BoltIcon, ChunksIcon } from '@/shared/icons/small';
import { pipsToPercentString } from '@/shared/utils';
import { getOpenSwapChannels } from 'packages/block-explorer/graphql/explorer/swaps';

const OpenChannelRow = ({ channel }: { channel: Channel }) => {
  const channelId = `${channel.issuedEvent.block.id}-${channel.sourceChain}-${channel.channelId}`;
  const brokerId = channel.broker.account.idSs58;
  const channelPath = `/channels/${channelId}`;
  const {
    destinationAddress,
    destinationChain,
    sourceChain,
    dcaNumberOfChunks,
    maxBoostFeeBps,
    fokRefundAddress: refundAddress,
  } = channel;

  return (
    <TableRow
      className="h-[91px] text-xs transition hover:cursor-pointer hover:bg-cf-gray-3"
      href={channelPath}
    >
      <td className="whitespace-nowrap">
        <div className="flex flex-col gap-y-2">
          <Link
            href={`/channels/${channelId}`}
            noPropagate
            underline
            className="font-aeonikMono text-12 uppercase"
          >
            {channelId}
          </Link>
          <div className="flex gap-x-2">
            {maxBoostFeeBps > 0 && (
              <Pill
                color="neutral"
                text="Boostable"
                Icon={BoltIcon}
                iconColor="text-cf-pink-1"
                maxWidth={false}
              />
            )}
            {dcaNumberOfChunks > 1 && (
              <Pill
                color="neutral"
                text={`${dcaNumberOfChunks} chunks`}
                Icon={ChunksIcon}
                iconColor="text-cf-light-3"
                maxWidth={false}
              />
            )}
          </div>
        </div>
      </td>
      <td>
        <Addresses
          destinationAddress={destinationAddress}
          destinationChain={destinationChain}
          refundAddress={refundAddress}
          sourceChain={sourceChain}
        />
      </td>
      <td>
        <div className="flex items-center gap-x-2 text-cf-light-2">
          <CompactSwapRoute
            routeInfo={{
              destinationAsset: channel.destinationAsset,
              sourceAsset: channel.sourceAsset,
            }}
            flat
          />
        </div>
      </td>
      <td className="font-aeonikMono">
        {formatUsdValue(
          BigNumber.sum(
            0,
            ...channel.swapsRequests.nodes.flatMap((r) => [
              r.executedSwaps.aggregates?.sum?.intermediateValueUsd ?? 0,
              r.executedSwaps.aggregates?.sum?.swapOutputValueUsd ?? 0,
            ]),
          ),
        )}
      </td>
      <td>
        <div className="flex flex-col gap-y-2 whitespace-nowrap">
          <div className="flex gap-x-1">
            <Link href={`/brokers/${brokerId}`} underline className="font-aeonikMono" noPropagate>
              {abbreviate(brokerId)}
            </Link>
            <CopyButton textToCopy={brokerId} />
          </div>

          {channel.broker.account.alias && (
            <Pill
              text={channel.broker.account.alias}
              color="neutral"
              tooltipContent={channel.broker.account.alias}
            />
          )}
        </div>
      </td>
      <td>{pipsToPercentString(channel.totalBrokerCommissionBps)}</td>
      <td
        className="whitespace-nowrap text-right font-aeonikMono text-cf-light-2"
        title={new Date(channel.issuedEvent.block.timestamp).toLocaleString()}
      >
        <AgeWithTooltip timestamp={channel.issuedEvent.block.timestamp} />
      </td>
    </TableRow>
  );
};

export default function OpenChannelsTable() {
  const { data, isLoading, paginationControls } = useRemotePagination(getOpenSwapChannels, {
    useQueryParam: false,
    refetchInterval: 60_000,
  });

  const rows = useMemo(
    () =>
      (data?.channels?.edges ?? []).map(({ node: channel }) => (
        <OpenChannelRow key={`${channel.sourceChain}-${channel.channelId}`} channel={channel} />
      )),
    [data],
  );

  return (
    <TableV2
      isLoading={isLoading}
      columns={[
        'Channel details',
        'Addresses',
        'Route',
        'Volume',
        'Broker',
        'Commission',
        { name: 'Created', alignment: 'right' },
      ]}
      rows={rows}
      paginationControls={paginationControls}
      emptyRowsContent="Currently no open channels"
    />
  );
}
