import { gql } from '@/shared/graphql/generated/explorer';

export const getSwapByNativeIdQuery = gql(/* GraphQL */ `
  query GetSwapByNativeId($nativeId: BigInt!) {
    swapRequest: swapRequestByNativeId(nativeId: $nativeId) {
      ...SwapRequest
      beneficiaries: swapRequestBeneficiariesBySwapRequestId(orderBy: TYPE_ASC) {
        nodes {
          id
          brokerCommissionRateBps
          type
          commissions: swapCommissionsBySwapRequestBeneficiaryId {
            groupedAggregates(groupBy: ASSET) {
              asset: keys
              sum {
                valueUsd
                amount
              }
            }
          }
          account: accountByAccountId {
            alias
            idSs58
            broker: brokerByAccountId {
              id
            }
          }
        }
      }
    }
  }
`);

export const getAllSwapsByAssetQuery = gql(/* GraphQL */ `
  query GetAllSwapRequestsByAsset($first: Int, $offset: Int, $baseAsset: ChainflipAsset!) {
    allSwapRequests(
      filter: {
        or: [
          { sourceAsset: { equalTo: $baseAsset } }
          { destinationAsset: { equalTo: $baseAsset } }
        ]
      }
      orderBy: ID_DESC
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...SwapRequest
        }
      }
      totalCount
    }
  }
`);

gql(/* GraphQL */ `
  fragment ChannelInfo on SwapChannel {
    id
    channelId
    depositAddress
    destinationAddress
    destinationChain
    issuedEventId
    sourceChain
    sourceAsset
    destinationChain
    destinationAsset
    maxBoostFeeBps
    isExpired
    sourceChainExpiryBlock
    openingFeePaid
    fokRefundAddress
    fokMinPriceX128
    dcaNumberOfChunks
    issuedBlock: blockByIssuedBlockId {
      id
      timestamp
    }
    issuedEvent: eventByIssuedEventId {
      indexInBlock
    }
  }
`);

export const getSwapChannelData = gql(/* GraphQL */ `
  query GetSwapChannelData(
    $channelId: BigInt!
    $sourceChain: ChainflipChain!
    $issuedBlockId: Int!
  ) {
    channel: swapChannelByIssuedBlockIdAndSourceChainAndChannelId(
      channelId: $channelId
      issuedBlockId: $issuedBlockId
      sourceChain: $sourceChain
    ) {
      ...ChannelInfo
      failedDeposits: failedDepositsBySwapDepositChannelId(orderBy: EVENT_ID_DESC) {
        nodes {
          id
          amount
          reason
          event: eventByEventId {
            id
            indexInBlock
            block: blockByBlockId {
              id
              timestamp
            }
          }
          broadcast: broadcastByRefundBroadcastId {
            broadcastSuccessEventId
            refs: transactionRefsByBroadcastId {
              nodes {
                ref
              }
            }
          }
        }
      }
      beneficiaries: swapChannelBeneficiariesByDepositChannelId {
        nodes {
          id
          brokerCommissionRateBps
          type
          commissions: swapCommissionsBySwapChannelBeneficiaryId {
            groupedAggregates(groupBy: ASSET) {
              asset: keys
              sum {
                valueUsd
                amount
              }
            }
          }
          account: accountByAccountId {
            alias
            idSs58
            broker: brokerByAccountId {
              id
            }
          }
        }
      }
      swapRequests: swapRequestsByDepositChannelId(orderBy: NATIVE_ID_DESC) {
        nodes {
          ...SwapRequestOverview
        }
      }
    }
    lastBlock: allBlocks(last: 1) {
      nodes {
        id
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment OpenChannel on SwapChannel {
    id
    totalBrokerCommissionBps
    channelId
    destinationAddress
    destinationAsset
    destinationChain
    sourceAsset
    sourceChain
    maxBoostFeeBps
    fokRefundAddress
    dcaNumberOfChunks
    issuedEvent: eventByIssuedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    broker: brokerByBrokerId {
      account: accountByAccountId {
        idSs58
        alias
      }
    }
    swapsRequests: swapRequestsByDepositChannelId {
      nodes {
        executedSwaps: swapsBySwapRequestId(filter: { swapExecutedEventId: { isNull: false } }) {
          totalCount
          aggregates {
            sum {
              intermediateValueUsd
              swapOutputValueUsd
            }
          }
        }
      }
    }
  }
`);

export const getOpenSwapChannels = gql(/* GraphQL */ `
  query GetOpenSwapChannels($first: Int!, $offset: Int) {
    channels: allSwapChannels(
      condition: { isExpired: false }
      orderBy: ISSUED_EVENT_ID_DESC
      offset: $offset
      first: $first
    ) {
      pageInfo {
        hasPreviousPage
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...OpenChannel
        }
      }
      totalCount
    }
  }
`);

gql(/* GraphQL */ `
  fragment BrokerSwapChannel on SwapChannel {
    ...OpenChannel
    isExpired
    openingFeePaid
  }
`);

export const getSwapChannelsByBrokerIdQuery = gql(/* GraphQL */ `
  query GetSwapChannelsByBrokerId($idSs58: String!, $first: Int, $offset: Int) {
    account: accountByIdSs58(idSs58: $idSs58) {
      broker: brokerByAccountId {
        channels: swapChannelsByBrokerId(
          orderBy: ISSUED_EVENT_ID_DESC
          offset: $offset
          first: $first
        ) {
          totalCount
          edges {
            node {
              ...BrokerSwapChannel
            }
          }
        }
      }
    }
  }
`);

export const getSwapChannelsByAffiliateBrokerIdQuery = gql(/* GraphQL */ `
  query GetSwapChannelsByAffiliateBrokerId($idSs58: String!, $first: Int, $offset: Int) {
    account: accountByIdSs58(idSs58: $idSs58) {
      beneficiaryChannels: swapChannelBeneficiariesByAccountId(
        orderBy: DEPOSIT_CHANNEL_ID_DESC
        offset: $offset
        first: $first
        condition: { type: AFFILIATE }
      ) {
        totalCount
        edges {
          node {
            swapChannel: swapChannelByDepositChannelId {
              ...BrokerSwapChannel
            }
          }
        }
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment EgressInfo on Egress {
    amount
    valueUsd
    event: eventByScheduledEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    broadcast: broadcastByBroadcastId {
      ...BroadcastInfo
    }
  }

  fragment IgnoredEgressInfo on IgnoredEgress {
    amount
    valueUsd
    error: stateChainErrorByStateChainErrorId {
      docs
      name
    }
    event: eventByEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
  }

  fragment BroadcastInfo on Broadcast {
    broadcastRequestedEventId
    broadcastAbortedEventId
    broadcastSuccessEventId
    transactionRef: transactionRefsByBroadcastId {
      nodes {
        ref
      }
    }
    successEvent: eventByBroadcastSuccessEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    requestedEvent: eventByBroadcastRequestedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    abortedEvent: eventByBroadcastAbortedEventId {
      indexInBlock
      block: blockByBlockId {
        id
        timestamp
      }
    }
    transactionPayload
  }
`);

export const getFailedDepositByTxRef = gql(/* GraphQL */ `
  query getFailedDepositByTxRef($txRef: String!) {
    txRef: transactionRefByRef(ref: $txRef) {
      failedDeposit: failedDepositByFailedDepositId {
        event: eventByEventId {
          indexInBlock
          block: blockByBlockId {
            id
            timestamp
          }
        }
        reason
      }
    }
  }
`);

export const getFailedDepositBySwapDepositChannelId = gql(/* GraphQL */ `
  query getFailedDepositBySwapDepositChannelId($depositChannelId: Int!) {
    failedDeposits: allFailedDeposits(
      first: 1
      orderBy: ID_DESC
      condition: { swapDepositChannelId: $depositChannelId }
    ) {
      nodes {
        event: eventByEventId {
          indexInBlock
          block: blockByBlockId {
            id
            timestamp
          }
        }
        reason
      }
    }
  }
`);
