import { eachUtcDayOfInterval } from '@chainflip/utils/date';
import { formatUsdValue } from '@chainflip/utils/number';
import { type BarTooltipProps, ResponsiveBar, type BarDatum } from '@nivo/bar';
import BigNumber from 'bignumber.js';
import { ChartLegendRectangle, Divider } from '@/shared/components';
import { ChartTooltip } from '@/shared/components/ChartTooltip';
import { TransparentBars } from '@/shared/components/TransparentBars';
import { useScreenSize } from '@/shared/hooks';
import { formatWithNumeral } from '@/shared/utils';
import { colors, getDateAxisTicks } from '@/shared/utils/charts';
import {
  type BrokerAggregateByDateRange,
  getBrokerIdOrAlias,
  getTopBrokersIdSs58,
} from '../../pages/brokers';

const BarTooltip = (props: BarTooltipProps<BarDatum> & { brokers: string[] }) => (
  <ChartTooltip>
    <div className="w-[240px] space-y-1">
      <div className="flex flex-col items-center">
        <div className="text-12 text-cf-light-2">
          {new Date(props.data.date).toLocaleDateString(undefined, { timeZone: 'UTC' })} UTC
        </div>
        <div className="text-24 text-cf-white">
          {formatUsdValue(BigNumber.sum(...props.brokers.map((broker) => props.data[broker] ?? 0)))}
        </div>
      </div>
      <div className="text-12 leading-6 text-cf-white">
        {props.brokers.map((broker, index) => (
          <div key={broker} className="flex flex-col gap-y-2.5">
            {broker === 'Others' && <Divider />}

            <div className="flex flex-row items-center justify-between text-cf-white" key={broker}>
              <div className="flex flex-row items-center space-x-2">
                <ChartLegendRectangle fill={colors[index]} />
                <div>{broker}</div>
              </div>
              <div>{formatUsdValue(Number(props.data[broker] ?? 0))}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </ChartTooltip>
);

const aggregateData = (
  startDate: Date,
  endDate: Date,
  allBrokers: NonNullable<BrokerAggregateByDateRange>,
) => {
  const chartDates = eachUtcDayOfInterval({
    start: startDate,
    end: endDate,
  });
  const chartAggregates = chartDates.flatMap((date) =>
    allBrokers.filter((item) => new Date(Number(item.day)).valueOf() === date.valueOf()),
  );
  const topBrokerSs58Ids = getTopBrokersIdSs58(chartAggregates, 'swapFeeUsd');

  const result: BarDatum[] = chartDates.map((date) => {
    const obj: BarDatum = { date: date.toISOString() };
    chartAggregates
      .filter((item) => new Date(Number(item.day)).valueOf() === date.valueOf())
      .forEach((item) => {
        if (item.idSs58 && topBrokerSs58Ids.includes(item.idSs58)) {
          obj[getBrokerIdOrAlias(item)] = item.swapFeeUsd || 0;
        } else {
          obj.Others = (Number(obj.Others) || 0) + (item.swapFeeUsd || 0);
        }
      });
    return obj;
  });

  return {
    total: formatUsdValue(
      chartAggregates.reduce((prev, curr) => prev + (curr.swapFeeUsd || 0), 0) || 0,
      false,
    ),
    chartSeries: result,
    chartDates,
    brokers: [
      ...new Set([
        ...topBrokerSs58Ids.map((idSs58) =>
          getBrokerIdOrAlias(chartAggregates.find((b) => b.idSs58 === idSs58)),
        ),
        'Others',
      ]),
    ],
  };
};

type ChartData = ReturnType<typeof aggregateData>;

const Chart = ({ data, loading }: { data: ChartData; loading: boolean }) => {
  const { isMobile } = useScreenSize();
  const brokerColors = Object.fromEntries(
    data.brokers.map((broker, index) => [broker, colors[index]]),
  );

  return (
    <ResponsiveBar
      data={data.chartSeries}
      maxValue={loading ? 1 : undefined}
      keys={data.brokers}
      colors={(datum) => brokerColors[String(datum.id)]}
      indexBy="date"
      margin={{ top: 20, right: 20, bottom: 50, left: 55 }}
      padding={0.4}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: isMobile ? -60 : 0,
        tickValues: getDateAxisTicks(data.chartSeries.map((item) => item.date.toString())),
        format: (dateString) =>
          new Date(dateString).toLocaleDateString(undefined, {
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC',
          }),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        format: (number) => formatWithNumeral(number),
      }}
      enableLabel={false}
      theme={{
        legends: {
          text: {
            fill: '#909090',
          },
        },
        axis: {
          ticks: {
            text: {
              fill: '#5F5F5F',
              fontFamily: 'Aeonik-Medium',
              fontSize: 12,
            },
          },
        },
        grid: {
          line: {
            stroke: '#252525',
            strokeWidth: 1,
            strokeDasharray: 2,
          },
        },
      }}
      defs={[
        {
          id: 'opacity',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: 'inherit', opacity: 1 },
            {
              offset: 100,
              color: 'inherit',
              opacity: 0.6,
            },
          ],
        },
      ]}
      fill={[{ match: '*', id: 'opacity' }]}
      animate={false}
      role="application"
      tooltip={(props) => BarTooltip({ ...props, brokers: data.brokers })}
      layers={['grid', 'axes', 'bars', TransparentBars]}
    />
  );
};

const getLegendLabels = (data: ChartData) => data.brokers;

export const feeChartProps = {
  getLegendLabels,
  Chart,
  aggregateData,
};
