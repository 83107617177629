import { gql } from '@/shared/graphql/generated/reporting';

export const getBrokersOverviewQueryV2 = gql(/* GraphQL */ `
  query GetBrokersOverviewV2($idSs58: String) {
    mainBrokers: allSwapRequests(
      condition: { isInternal: false }
      filter: { executedChunks: { greaterThan: 0 }, mainBrokerAccountSs58Id: { isNull: false } }
    ) {
      agg: groupedAggregates(groupBy: [MAIN_BROKER_ACCOUNT_SS58_ID]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          fee: mainBrokerFeeValueUsd
          swapCount
        }
      }
    }
    affiliate1: allSwapRequests(
      condition: { mainBrokerAccountSs58Id: $idSs58 }
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker1AccountSs58Id: { isNull: false }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER1_ACCOUNT_SS58_ID]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          fee: affiliateBroker1FeeValueUsd
          swapCount
        }
        min {
          minCommissionBps: affiliateBroker1CommissionBps
        }
        max {
          maxCommissionBps: affiliateBroker1CommissionBps
        }
      }
    }
    affiliate2: allSwapRequests(
      condition: { isInternal: false, mainBrokerAccountSs58Id: $idSs58 }
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker2AccountSs58Id: { isNull: false }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER2_ACCOUNT_SS58_ID]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          fee: affiliateBroker2FeeValueUsd
          swapCount
        }
        min {
          minCommissionBps: affiliateBroker2CommissionBps
        }
        max {
          maxCommissionBps: affiliateBroker2CommissionBps
        }
      }
    }
    affiliate3: allSwapRequests(
      condition: { isInternal: false, mainBrokerAccountSs58Id: $idSs58 }
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker3AccountSs58Id: { isNull: false }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER3_ACCOUNT_SS58_ID]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          fee: affiliateBroker3FeeValueUsd
          swapCount
        }
        min {
          minCommissionBps: affiliateBroker3CommissionBps
        }
        max {
          maxCommissionBps: affiliateBroker3CommissionBps
        }
      }
    }
    affiliate4: allSwapRequests(
      condition: { isInternal: false, mainBrokerAccountSs58Id: $idSs58 }
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker4AccountSs58Id: { isNull: false }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER4_ACCOUNT_SS58_ID]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          fee: affiliateBroker4FeeValueUsd
          swapCount
        }
        min {
          minCommissionBps: affiliateBroker4CommissionBps
        }
        max {
          maxCommissionBps: affiliateBroker4CommissionBps
        }
      }
    }
    affiliate5: allSwapRequests(
      condition: { isInternal: false, mainBrokerAccountSs58Id: $idSs58 }
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker5AccountSs58Id: { isNull: false }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER5_ACCOUNT_SS58_ID]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          fee: affiliateBroker5FeeValueUsd
          swapCount
        }
        min {
          minCommissionBps: affiliateBroker5CommissionBps
        }
        max {
          maxCommissionBps: affiliateBroker5CommissionBps
        }
      }
    }
  }
`);

export const getAllAccountsQuery = gql(/* GraphQL */ `
  query GetAllAccounts {
    allAccounts(filter: { role: { in: [BROKER, UNREGISTERED] } }) {
      nodes {
        idSs58
        alias
      }
    }
  }
`);

export const getAllBrokersOverviewQuery = gql(/* GraphQL */ `
  query GetAllBrokersOverview {
    allSwapRequests(
      condition: { isInternal: false }
      filter: { executedChunks: { greaterThan: 0 }, mainBrokerAccountSs58Id: { isNull: false } }
    ) {
      agg: groupedAggregates(groupBy: [MAIN_BROKER_ACCOUNT_SS58_ID]) {
        keys
        sum {
          intermediateValueUsd
          outputValueUsd
          mainBrokerFeeValueUsd
        }
        swapCount: distinctCount {
          total: swapRequestNativeId
        }
      }
    }
  }
`);

export const getBrokersAggregateByDateRangeReportingQuery = gql(/* GraphQL */ `
  query GetBrokersAggregateByDateRangeReporting($startDate: Datetime, $endDate: Datetime) {
    mainBrokers: allSwapRequests(
      filter: {
        executedChunks: { greaterThan: 0 }
        mainBrokerAccountSs58Id: { isNull: false }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [MAIN_BROKER_ACCOUNT_SS58_ID, COMPLETED_BLOCK_DATE]) {
        keys
        sum {
          outputAndIntermediateValueUsd
          feeUsd: mainBrokerFeeValueUsd
          swapCount
        }
      }
    }
    affiliate1: allSwapRequests(
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker1AccountSs58Id: { isNull: false }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER1_ACCOUNT_SS58_ID, COMPLETED_BLOCK_DATE]) {
        keys
        sum {
          feeUsd: affiliateBroker1FeeValueUsd
        }
      }
    }
    affiliate2: allSwapRequests(
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker2AccountSs58Id: { isNull: false }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER2_ACCOUNT_SS58_ID, COMPLETED_BLOCK_DATE]) {
        keys
        sum {
          feeUsd: affiliateBroker2FeeValueUsd
        }
      }
    }
    affiliate3: allSwapRequests(
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker3AccountSs58Id: { isNull: false }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER3_ACCOUNT_SS58_ID, COMPLETED_BLOCK_DATE]) {
        keys
        sum {
          feeUsd: affiliateBroker3FeeValueUsd
        }
      }
    }
    affiliate4: allSwapRequests(
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker4AccountSs58Id: { isNull: false }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER4_ACCOUNT_SS58_ID, COMPLETED_BLOCK_DATE]) {
        keys
        sum {
          feeUsd: affiliateBroker4FeeValueUsd
        }
      }
    }
    affiliate5: allSwapRequests(
      filter: {
        executedChunks: { greaterThan: 0 }
        affiliateBroker5AccountSs58Id: { isNull: false }
        completedBlockTimestamp: { greaterThanOrEqualTo: $startDate, lessThanOrEqualTo: $endDate }
      }
    ) {
      agg: groupedAggregates(groupBy: [AFFILIATE_BROKER5_ACCOUNT_SS58_ID, COMPLETED_BLOCK_DATE]) {
        keys
        sum {
          feeUsd: affiliateBroker5FeeValueUsd
        }
      }
    }
  }
`);
