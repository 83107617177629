import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { brokerAliasMap } from '@chainflip/utils/consts';
import { toEndOfUtcDay, toStartOfUtcDay } from '@chainflip/utils/date';
import { formatUsdValue } from '@chainflip/utils/number';
import { abbreviate } from '@chainflip/utils/string';
import { UTCDate } from '@date-fns/utc';
import { subDays } from 'date-fns';
import { Link, SearchInput, TabPanel } from '@/shared/components';
import type { GetAllBrokersOverviewQuery } from '@/shared/graphql/generated/reporting/graphql';
import { useDebouncedEffect } from '@/shared/hooks';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { formatWithCommas, keys, localDayToUTCDay, sortByValues } from '@/shared/utils';
import OpenChannelsTable from 'packages/block-explorer/components/SwapsPage/OpenChannelsTable';
import {
  getAllBrokersOverviewQuery,
  getBrokersAggregateByDateRangeReportingQuery,
} from 'packages/block-explorer/graphql/reporting/broker';
import { BrokerAccountsTable } from '../../components/BrokerPage/BrokerAccountsTable';
import { feeChartProps } from '../../components/BrokerPage/BrokerFeeChart';
import { BrokerStatsWithChart } from '../../components/BrokerPage/BrokerStatsWithChart';
import { swapChartProps } from '../../components/BrokerPage/BrokerSwapCountChart';
import { volumeChartProps } from '../../components/BrokerPage/BrokerVolumeChart';
import { Layout } from '../../components/Layout';
import { PeriodSelector } from '../../components/SwapsPage/PeriodSelector';

export type BrokerAggregateByDateRange = {
  day: string;
  idSs58?: string | null;
  alias?: string | null;
  volume?: number | null;
  swapCount?: number | null;
  swapFeeUsd?: number | null;
}[];

export const getBrokerAlias = (broker?: { alias?: string | null; idSs58?: string | null }) =>
  broker?.alias || brokerAliasMap[broker?.idSs58 as string]?.name;

export const getBrokerIdSs58 = (broker?: { alias?: string | null }) =>
  Object.entries(brokerAliasMap).find(([_, value]) => value.name === broker?.alias)?.[0];

export const getBrokerIdOrAlias = (broker?: { alias?: string | null; idSs58?: string | null }) =>
  broker && broker.idSs58 ? getBrokerAlias(broker) || abbreviate(broker.idSs58, 4) : 'Others';

export const getTopBrokersIdSs58 = (
  items: NonNullable<BrokerAggregateByDateRange>,
  metric: 'volume' | 'swapFeeUsd' | 'swapCount',
) => {
  const metricBySs58 = items?.reduce(
    (acc, curr) =>
      curr.idSs58 ? { ...acc, [curr.idSs58]: (acc[curr.idSs58] ?? 0) + (curr[metric] ?? 0) } : acc,
    {} as Record<string, number>,
  );

  return keys(sortByValues(metricBySs58)).slice(0, 5);
};

const aggregateStats = (allBrokerStats: GetAllBrokersOverviewQuery | undefined) => {
  const brokers = (allBrokerStats?.allSwapRequests?.agg ?? []).map((broker) => ({
    key: broker.keys![0],
    volume: Number(broker.sum?.intermediateValueUsd) + Number(broker.sum?.outputValueUsd),
    fees: Number(broker.sum?.mainBrokerFeeValueUsd),
    swapCount: Number(broker?.swapCount?.total),
    broker: {
      account: {
        idSs58: broker.keys![0],
        alias: getBrokerAlias({ idSs58: broker.keys![0] }),
      },
    },
  }));

  const formatter = (broker: (typeof brokers)[number]) => ({
    ...broker,
    volume: formatUsdValue(broker.volume, false),
    fees: formatUsdValue(broker.fees, false),
    swapCount: formatWithCommas(broker.swapCount),
  });

  const sortBrokersByKey = (items: typeof brokers, key: keyof (typeof brokers)[0]) =>
    items.sort((a, b) => {
      const aVal = Number(a[key]);
      const bVal = Number(b[key]);
      return bVal - aVal;
    });

  return {
    volume: {
      formattedTotalValue: formatUsdValue(
        brokers.reduce((acc, broker) => acc + broker.volume, 0),
        false,
      ),
      topAccounts: sortBrokersByKey(brokers, 'volume')
        .slice(0, 5)
        .map((broker) => formatter(broker)),
    },
    fees: {
      formattedTotalValue: formatUsdValue(
        brokers.reduce((acc, brokerOverview) => acc + Number(brokerOverview.fees ?? 0), 0),
        false,
      ),
      topAccounts: sortBrokersByKey(brokers, 'fees')
        .slice(0, 5)
        .map((broker) => formatter(broker)),
    },
    swapCount: {
      formattedTotalValue: formatWithCommas(
        brokers.reduce((acc, broker) => acc + broker.swapCount, 0),
      ),
      topAccounts: sortBrokersByKey(brokers, 'swapCount')
        .slice(0, 5)
        .map((broker) => formatter(broker)),
    },
  };
};

export type OverviewStats = ReturnType<typeof aggregateStats>;

export default function BrokerOverview() {
  const { query } = useRouter();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  useDebouncedEffect(() => setDebouncedSearchText(searchText), [searchText], 300);
  const [chartStartDate, setChartStartDate] = useState(() =>
    toStartOfUtcDay(localDayToUTCDay(subDays(new Date(), 13))),
  );
  const [chartEndDate, setChartEndDate] = useState(() =>
    toEndOfUtcDay(localDayToUTCDay(new Date())),
  );
  const [selectedDates, setSelectedDates] = useState([chartStartDate, chartEndDate]);

  const queryOptions = {
    refetchInterval: 60_000,
  } as const;

  const { data: allBrokerStats, isLoading: allBrokerStatsLoading } = useGqlQuery(
    getAllBrokersOverviewQuery,
    { ...queryOptions, context: { clientName: 'reportingService' } },
  );

  const { data: brokerAggregate, isLoading: brokerAggregateLoading } = useGqlQuery(
    getBrokersAggregateByDateRangeReportingQuery,
    {
      ...queryOptions,
      context: { clientName: 'reportingService' },
      variables: {
        startDate: chartStartDate.toISOString(),
        endDate: chartEndDate.toISOString(),
      },
    },
  );

  const formattedMainBrokerRawData = useMemo(
    () =>
      brokerAggregate?.mainBrokers?.agg?.map((broker) => ({
        day: new UTCDate(broker?.keys?.at(1) ?? '').getTime().toString(),
        idSs58: broker.keys?.at(0),
        alias: getBrokerAlias({ idSs58: broker.keys?.at(0) }),
        volume: Number(broker.sum?.outputAndIntermediateValueUsd ?? 0),
        swapCount: Number(broker.sum?.swapCount ?? 0),
        swapFeeUsd: Number(broker.sum?.feeUsd ?? 0),
      })) ?? [],
    [brokerAggregate],
  );

  const formattedBrokerFeeRawData = useMemo(
    () =>
      [
        brokerAggregate?.mainBrokers,
        brokerAggregate?.affiliate1,
        brokerAggregate?.affiliate2,
        brokerAggregate?.affiliate3,
        brokerAggregate?.affiliate4,
        brokerAggregate?.affiliate5,
      ]
        .filter(Boolean)
        .flatMap((value) =>
          (value?.agg ?? []).map((broker) => ({
            day: toStartOfUtcDay(localDayToUTCDay(new Date(broker?.keys?.at(1) ?? '')))
              .getTime()
              .toString(),
            idSs58: broker.keys?.at(0),
            alias: getBrokerAlias({ idSs58: broker.keys?.at(0) }),
            swapFeeUsd: Number(broker.sum?.feeUsd ?? 0),
          })),
        ),
    [brokerAggregate],
  );

  const brokerStats = useMemo(() => aggregateStats(allBrokerStats), [allBrokerStats]);

  return (
    <Layout title="Brokers">
      <div className="flex flex-col gap-y-8">
        <div className="flex flex-col gap-y-8">
          <div className="flex flex-row flex-wrap items-center justify-between gap-y-4">
            <div className="flex flex-col">
              <div className="text-[32px] text-white">Brokers</div>

              <div className="text-14 text-cf-light-2">
                Brokers are State Chain accounts responsible for opening deposit channels on behalf
                of end users.{' '}
                <Link
                  href="https://docs.chainflip.io/concepts/swaps-amm/deposit-channels-and-brokers#brokers"
                  underline
                  target="_blank"
                >
                  Learn more{' '}
                </Link>
              </div>
            </div>
            <PeriodSelector
              startDate={chartStartDate}
              setStartDate={setChartStartDate}
              endDate={chartEndDate}
              setEndDate={setChartEndDate}
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              buttonSize="small"
            />
          </div>
          <TabPanel
            shouldOverflow={false}
            nodes={[
              {
                name: 'Volume',
                content: (
                  <BrokerStatsWithChart
                    {...volumeChartProps}
                    startDate={chartStartDate}
                    endDate={chartEndDate}
                    rawData={formattedMainBrokerRawData || []}
                    loading={brokerAggregateLoading || allBrokerStatsLoading}
                    stat="volume"
                    overviewStats={brokerStats}
                  />
                ),
              },
              {
                name: 'Swaps',
                content: (
                  <BrokerStatsWithChart
                    {...swapChartProps}
                    startDate={chartStartDate}
                    endDate={chartEndDate}
                    rawData={formattedMainBrokerRawData || []}
                    loading={brokerAggregateLoading || allBrokerStatsLoading}
                    stat="swapCount"
                    overviewStats={brokerStats}
                  />
                ),
              },
              {
                name: 'Fees',
                content: (
                  <BrokerStatsWithChart
                    {...feeChartProps}
                    startDate={chartStartDate}
                    endDate={chartEndDate}
                    loading={brokerAggregateLoading || allBrokerStatsLoading}
                    rawData={formattedBrokerFeeRawData ?? []}
                    stat="fees"
                    overviewStats={brokerStats}
                  />
                ),
              },
            ]}
          />
        </div>
        <div className="flex flex-col gap-y-6">
          <div className="flex w-full self-end md:w-[512px]">
            <SearchInput
              disabled={query.table === 'Deposit Channel'}
              value={searchText}
              onChange={setSearchText}
              placeholder="Search for a broker ID"
            />
          </div>
          <TabPanel
            queryParamName="table"
            shouldOverflow={false}
            nodes={[
              {
                name: 'Brokers & Affiliates',
                content: <BrokerAccountsTable searchText={debouncedSearchText} />,
              },
              {
                name: 'Deposit Channel',
                content: <OpenChannelsTable />,
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  );
}
