import { useMemo } from 'react';
import { formatUsdValue } from '@chainflip/utils/number';
import { abbreviate } from '@chainflip/utils/string';
import { CopyButton, Link, TokenLogo, Tooltip } from '@/shared/components';
import Pill from '@/shared/components/flip-ui-kit/Pill';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import TableRow from '@/shared/components/TableRow';
import { TABLE_POLL_INTERVAL } from '@/shared/constants';
import { type GetBrokersBalancesQuery } from '@/shared/graphql/generated/cache/graphql';
import { type GetBrokersOverviewV2Query } from '@/shared/graphql/generated/reporting/graphql';
import { useTableSort } from '@/shared/hooks';
import useFrontendPagination from '@/shared/hooks/useFrontendPagination';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { BrokerIcon, UsersIcon } from '@/shared/icons/small';
import { TokenAmount, COMPARATORS, chainflipAssetMap, formatWithCommas } from '@/shared/utils';
import { getBrokersBalancesQuery } from '../../graphql/cache/broker';
import { getBrokersOverviewQueryV2 } from '../../graphql/reporting/broker';
import { getBrokerAlias } from '../../pages/brokers';

type BrokerRowData = {
  idSs58: string;
  alias?: string;
  collectedFeesUsd: number;
  channelVolumeUsd: number;
  flipBalance: TokenAmount;
  swapCount: number;
  isSubmitter: boolean;
  isAffiliate: boolean;
};
const BrokerAccountRow = ({
  idSs58,
  alias,
  collectedFeesUsd,
  channelVolumeUsd,
  flipBalance,
  swapCount,
  isSubmitter,
  isAffiliate,
}: BrokerRowData) => (
  <TableRow
    className="transition:ease-in text-14 duration-150 hover:cursor-pointer hover:bg-cf-gray-3"
    href={`/brokers/${idSs58}`}
  >
    <td className="font-aeonikMono">
      <div className="flex items-center gap-x-1 whitespace-nowrap">
        <Tooltip content={idSs58}>
          <Link href={`/brokers/${idSs58}`} underline noPropagate>
            {abbreviate(idSs58, 8)}
          </Link>
        </Tooltip>
        <CopyButton textToCopy={idSs58} />
        {isSubmitter && <Pill Icon={BrokerIcon} text="Broker" color="neutral" />}
        {isAffiliate && <Pill Icon={UsersIcon} text="Affiliate" color="neutral" />}
        {alias && <Pill text={alias} color="neutral" tooltipContent={alias} />}
      </div>
    </td>
    <td className="font-aeonikMono">{formatUsdValue(collectedFeesUsd.toFixed())}</td>
    <td className="font-aeonikMono">{formatUsdValue(channelVolumeUsd.toFixed())}</td>
    <td className="font-aeonikMono">
      <div className="flex items-center gap-x-1">
        <TokenLogo token="Flip" />
        <span className="text-cf-white">{formatWithCommas(flipBalance.toFormatted())}</span>
        <span className="pt-0.5 text-12 text-cf-light-2">{chainflipAssetMap.Flip.symbol}</span>
      </div>
    </td>
    <td className="text-right font-aeonikMono">{formatWithCommas(swapCount)}</td>
  </TableRow>
);

const extractBrokerData = ({
  cacheData,
  details,
}: {
  cacheData?: GetBrokersBalancesQuery;
  details?: GetBrokersOverviewV2Query;
}): BrokerRowData[] => {
  if (!cacheData || !details) return [];

  const brokers =
    cacheData.brokers?.nodes
      .map((broker) => ({ ...broker, alias: getBrokerAlias({ idSs58: broker.idSs58 }) }))
      .map((broker) => {
        const main = details.mainBrokers?.agg?.find(({ keys }) => keys?.[0] === broker.idSs58);
        const affiliate1 = details.affiliate1?.agg?.find(({ keys }) => keys?.[0] === broker.idSs58);
        const affiliate2 = details.affiliate2?.agg?.find(({ keys }) => keys?.[0] === broker.idSs58);
        const affiliate3 = details.affiliate3?.agg?.find(({ keys }) => keys?.[0] === broker.idSs58);
        const affiliate4 = details.affiliate4?.agg?.find(({ keys }) => keys?.[0] === broker.idSs58);
        const affiliate5 = details.affiliate5?.agg?.find(({ keys }) => keys?.[0] === broker.idSs58);

        const records = [main, affiliate1, affiliate2, affiliate3, affiliate4, affiliate5];

        const total = records.reduce(
          (acc, curr) => ({
            outputAndIntermediateValueUsd:
              acc.outputAndIntermediateValueUsd +
              Number(curr?.sum?.outputAndIntermediateValueUsd ?? 0),
            fee: acc.fee + Number(curr?.sum?.fee ?? 0),
            swapCount: acc.swapCount + Number(curr?.sum?.swapCount ?? 0),
          }),
          {
            outputAndIntermediateValueUsd: 0,
            fee: 0,
            swapCount: 0,
          },
        );

        return {
          idSs58: broker.idSs58,
          alias: broker.alias,
          collectedFeesUsd: total.fee,
          swapCount: total.swapCount,
          channelVolumeUsd: total.outputAndIntermediateValueUsd,
          flipBalance: TokenAmount.fromAsset(broker?.flipBalance ?? 0, 'Flip'),
          isSubmitter: main?.keys?.includes(broker.idSs58) ?? false,
          isAffiliate: records.slice(1).some((record) => record?.keys?.includes(broker.idSs58)),
        };
      }) ?? [];

  return brokers;
};

const SORT_FUNCTIONS: ((broker: BrokerRowData) => number)[] = [
  () => 0, // no sort
  (broker) => broker.collectedFeesUsd,
  (broker) => broker.channelVolumeUsd,
  (broker) => broker.flipBalance.toNumber(),
  (broker) => broker.swapCount,
];

export const BrokerAccountsTable = ({ searchText }: { searchText: string }) => {
  const { sort, setCol } = useTableSort({ initialSort: { col: 1, direction: 'DESC' } });

  const { isLoading: cacheDataLoading, data: cacheData } = useGqlQuery(getBrokersBalancesQuery, {
    refetchInterval: TABLE_POLL_INTERVAL,
    context: { clientName: 'statechainCache' },
  });

  const { isLoading: processorDataLoading, data: brokerDetails } = useGqlQuery(
    getBrokersOverviewQueryV2,
    {
      refetchInterval: TABLE_POLL_INTERVAL,
      context: { clientName: 'reportingService' },
    },
  );

  const parsedData = useMemo(
    () => extractBrokerData({ cacheData, details: brokerDetails }),
    [cacheData, brokerDetails],
  );

  const sortedBrokers = useMemo(
    () =>
      parsedData
        .filter(
          (broker) =>
            searchText === '' ||
            broker.idSs58.toLowerCase().includes(searchText.toLowerCase()) ||
            broker.alias?.includes(searchText),
        )
        .sort((a, b) =>
          COMPARATORS[sort.direction](SORT_FUNCTIONS[sort.col](a), SORT_FUNCTIONS[sort.col](b)),
        ),
    [sort, searchText, parsedData],
  );

  const { data: paginatedData, paginationControls } = useFrontendPagination(sortedBrokers, {
    initialLimit: 25,
    paginationLimits: [25, 100, 150, 250],
  });

  return (
    <TableV2
      columns={[
        { name: 'Account ID', sortable: false },
        'Collected Fees',
        'Volume',
        'Balance (FLIP)',
        { alignment: 'right', name: 'Swaps' },
      ]}
      isLoading={cacheDataLoading || processorDataLoading}
      rows={paginatedData.edges.map(({ node }) => (
        <BrokerAccountRow
          key={node.idSs58}
          alias={getBrokerAlias(node)}
          idSs58={node.idSs58}
          collectedFeesUsd={node.collectedFeesUsd}
          channelVolumeUsd={node.channelVolumeUsd}
          swapCount={node.swapCount}
          flipBalance={node.flipBalance}
          isSubmitter={node.isSubmitter}
          isAffiliate={node.isAffiliate}
        />
      ))}
      paginationControls={paginationControls}
      sorting={{ setCol, sort }}
    />
  );
};
